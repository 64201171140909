@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply box-border bg-white;
}

/* Cacher les scrollbars pour tous les éléments */
* {
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none;  /* Pour Internet Explorer et Edge */
}

*::-webkit-scrollbar {
  display: none; /* Pour Chrome, Safari et Opera */
}


@media (max-width: 640px) { /* ou un autre point de rupture selon vos besoins */
  .small-screen-height {
    height: 50vh;
  }
}

.slider-image {
  height: 150px; /* Hauteur par défaut pour les petits écrans */
}

/* Media Query pour les écrans d'au moins 768 pixels de large */
@media (min-width: 768px) {
  .slider-image {
    height: 500px; /* Hauteur pour les grands écrans */
  }
}
